export default {
  1: {
    questionText: 'How would you describe the world in one word?',
    promptText: 'The world is'
  },
  2: {
    questionText: 'What are you hopeful about?',
    promptText: `I'm hopeful about`
  },
  3: {
    questionText: `It's the age of... (finish the sentence)`,
    promptText: 'Age of'
  },
  4: {
    questionText: 'Voting is... (finish the sentence)',
    promptText: 'Voting is'
  },
  5: {
    questionText: 'I am... (finish the sentence)',
    promptText: 'I am'
  }
}