import axios from 'axios';
import config from '../../config';

let client;

const initClient = async () => {
  console.log('client: ', client);
  // Load GAPI client
  return new Promise((res, rej) => {
    if(!client) {
      console.log('load client');
      window.gapi.load("client", () => {
        window.gapi.client
        .init({
          apiKey: config.GOOGLE_API_KEY,
          discoveryDocs: config.discoveryDocs,
          scope: 'https://www.googleapis.com/auth/spreadsheets'
        })
        .then(() => {
          window.gapi.client.load("sheets", "v4", () => {
            console.log('load sheets v4 api');
            client = window.gapi.client;
            res();
          });
        })
        .catch(err => {
          console.log('gapi init err: ', err);
          client = null;
          rej(err);
        })
      });
      } else {
        res();
     }
  });
};

export const submitResponse = async (userResponse, sheetIndex) => {
  console.log('in submit response: ', userResponse);
  const url = 'https://script.google.com/macros/s/AKfycbze4p0OGkEmMO9qcwJudCrkJfgR1e4tbnE62a1z8Yn1V6Q1wVOg/exec';
  const urlSafeResponse = encodeURIComponent(userResponse);

  console.log('urlSafeResponse: ', urlSafeResponse);

  return axios.get(`${url}?response=${urlSafeResponse}&sheetIndex=${sheetIndex}`)
    .then((result) => {
      return result;
    })
    .catch((err) => {
      console.log('err: ', err);
      return err;
    });
}

export const getResponses = async (sheetName) => {
  if(!client) {
    await initClient();
  }
  return client.sheets.spreadsheets.values
    .get({
      spreadsheetId: config.GOOGLE_SHEET_ID,
      range: `${sheetName}!A1:T`
    })
    .then(response => {
      const data = response.result.values;

      data.shift();

      return data.filter(d => {
        return d.length === 2 && d[1] === 'Approved';
      })
      .map(d => d[0]);
    })
    .catch(err => {
      console.log('err: ', err);
      return err;
    });
}