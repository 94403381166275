import React, { useEffect, useState } from 'react';
import { Button } from 'rebass';
import rFontPath from '../../assets/fonts/Righteous-Regular.ttf';
import blackCasperFontPath from '../../assets/fonts/BlackCasper.ttf';
import ransFontPath from '../../assets/fonts/rans____.ttf';
import revistaFontPath from '../../assets/fonts/REVISTA.ttf';

import ageOfVideoPath from '../../assets/AOU - Overaly .mov';
import ageOfVideo2Path from '../../assets/Sequence 09.mp4';
import ageOfVideo3Path from '../../assets/Sequence 11.mp4';

const DOC = document.documentElement;

window.onresize = () => {
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  height = windowHeight;
  width = windowWidth;

  centerX = width / 2;
  centerY = height / 2;

  quarterX = width / 4;
  quarterY = width / 4;

  WIDTH_15 = width / 15;

  if(myp5) {
    myp5.resizeCanvas(windowWidth, windowHeight);
  }
}

let p5, myp5;
let p5Interval;
let prevLevels = new Array(50);

let c, mic, amplitude;
let windowHeight = window.innerHeight;
let windowWidth = window.innerWidth;
let height = windowHeight;
let width = windowWidth;

let centerX = width / 2;
let centerY = height / 2;
let quarterX = width / 4;
let quarterY = width / 4;

let WIDTH_15 = width / 15;

let blackCasperFont;
let revistaFont;
let ransFont;
let rFont;

let ageOfVideo;
let ageOfVideo2;
let ageOfVideo3;

let eyes = [];
let eyesCount;
let eyesAngle = 0;

let u = 100;
let l = 40;

function Eye(_x, _y) {
  this.x = _x;
  this.y = _y;
  this.a = 0;
  this.isHidden = false;
}

Eye.prototype.draw1 = function(p, level) {
  if(!this.isHidden) {
    p.push();
    p.translate(this.x, this.y);
    p.fill(255);
    p.arc(0, -(10 + 10*level), (l + l*level), (l + l*level), 0.5, p.PI-0.5);
    p.arc(0, (10 + 10*level), l, l, p.PI+0.5, -0.5);
    p.pop();
  }
}

let scalar = 4;

Eye.prototype.draw2 = function(p, angle) {
  if(!this.isHidden) {
    p.push();
    p.translate(this.x, this.y);
    p.rotate(this.a);
  
    var x = scalar * p.cos(angle);
    var y = scalar * p.sin(angle);
  
    p.fill(0);
    p.ellipse(x, y, l/2, l/2);
    
    p.pop();
  }
}

Eye.prototype.setIsHidden = function(isHidden) {
  this.isHidden = isHidden;
}

function Pill(_x, _y) {
  this.x = _x;
  this.y = _y;
  this.a = 0;
  this.isHidden = false;
}

Pill.prototype.draw1 = function(p, level) {
  if(!this.isHidden) {
    p.push();
    
    p.pop();
  }
}

Pill.prototype.setIsHidden = function(isHidden) {
  this.isHidden = isHidden;
}

let Objects = [];
let NB = 50;
let NB_FRAMES = 50;

function activation(t, p) {
  return ((1-p.cos(2*p.PI*t))/2)**1;
}

function object(id, p) {
    
    this.id = id;
    
    this.draw = function(p, level) {
      var t = ((p.frameCount) % NB_FRAMES)/ NB_FRAMES;
      
      var x0 = p.lerp(0, width, this.id/NB_FRAMES);
      
      let theta = p.PI/2;
      
      var xx = x0;
      var yy = 0;
      
      var Nt = 75;
      
      var step = height/Nt;
      
      var turn = p.lerp(0,0.4, activation((this.id/NB+0*t)%1, p));
      
      p.push()
      
      p.stroke(100,100,100); 
      p.strokeWeight(3 - (10 * level));
      p.noFill();
      p.beginShape();
      
      p.vertex(xx,yy);
      
      for(var i=0;i<=Nt;i++){
        theta += turn*p.sin(100*p.noise(1000)+2*p.PI*(15*p.noise(0.2*this.id/NB_FRAMES,0.02*i)+t));
        xx += step * p.cos(theta);
        yy += step * p.sin(theta);
        
        var xx2 = p.lerp(xx,x0,(i/Nt)*(i/Nt)*(i/Nt));
        var yy2 = p.lerp(yy,p.lerp(0,height-0,i/Nt),p.max((i/Nt),1-p.sqrt(i/Nt)));
        
        p.vertex(xx2,yy2);
            
      }
      p.endShape();   
      p.pop();
    }
}

function WatchCanvas(props) {

  const [isLoading, setIsLoading] = useState(true);
  const myRef = React.createRef()

  useEffect(() => {
    // Wait until p5 is loaded
    p5Interval = window.setInterval(() => {
      if(window.p5) {
        p5 = window.p5;
        myp5 = new p5(Sketch, myRef.current);
        window.clearInterval(p5Interval);
      }
    }, 100);
  }, []);

  const FONTS = [blackCasperFont, revistaFont, ransFont, rFont];

  const Sketch = (p) => {

    p.setup = async function() {
      c = p.createCanvas(windowWidth, windowHeight);
      p.background(0);
      p.noStroke();
    
      p.rectMode(p.CENTER);
      p.colorMode(p.HSB);

      blackCasperFont = p.loadFont(blackCasperFontPath);
      revistaFont = p.loadFont(revistaFontPath);
      ransFont = p.loadFont(ransFontPath);
      rFont = p.loadFont(rFontPath);

      // p.textFont(blackCasperFont);
      p.textAlign(p.CENTER, p.CENTER);
      p.ellipseMode(p.CENTER);
    
      mic = new p5.AudioIn();
      let sources = await mic.getSources();
      mic.setSource(1);
      mic.start();
    
      // amplitude = new p5.Amplitude();
      // amplitude.setInput(mic);
      // amplitude.smooth(0.6);

      // Align images to center
      p.imageMode(p.CENTER);
      ageOfVideo = p.createCapture(p.VIDEO, () => {
        ageOfVideo.size(width / 10, height / 10);
      });
      ageOfVideo2 = p.createVideo([ageOfVideo2Path]);
      ageOfVideo3 = p.createVideo([ageOfVideo3Path]);
    };

    p.draw = () => {
      p.background(0);
    
      var level = mic.getLevel();
      console.log('level: ', level);

      if(p.ageOfVideo) {
        if(p.ageOfVideo === 'v') {
          ageOfVideo.size(width + (WIDTH_15 * level), height + (WIDTH_15 * level));
        } else if(p.ageOfVideo === 'b') {
          ageOfVideo2.size(width + (WIDTH_15 * level), height + (WIDTH_15 * level));
        } else if(p.ageOfVideo === 'n') {
          ageOfVideo3.size(width + (WIDTH_15 * level), height + (WIDTH_15 * level));
        }
      } else {


        /* SOUND EFFECT */
        if(p.soundEffect) {

          // sound levels
          var spacing = 10;
          var w = width/ (prevLevels.length * spacing);

          var minHeight = 2;

          // add new level to end of array
          prevLevels.push(level);

          // remove first item in array
          prevLevels.splice(0, 1);

          for (var i = 0; i < prevLevels.length; i++) {

            var y = p.map(i, prevLevels.length, 0, centerY, height);
            var x = p.map(i, prevLevels.length, 0, centerX, width);
            var h = p.map(prevLevels[i], 0, 0.5, minHeight, height);

            var alphaValue = p.log(i, 0, prevLevels.length, 1, 250);
            var hueValue = p.map(h, minHeight, height, 200, 255);

            p.fill(hueValue, hueValue, hueValue, alphaValue);

            if(p.soundEffect === 'e') {

              p.ellipse(x + WIDTH_15, centerY, w, h);
              p.ellipse(width - x - WIDTH_15, centerY, w, h);

            } else if(p.soundEffect === 'a') {

              p.push();
              p.arc(centerX, (y + 100), h + 50, w - 50, 2 * p.PI, 0)
              p.arc(centerX, height - (y + 100), h + 50, w - 50, 0, 2 * p.PI);

              p.arc(x + WIDTH_15, centerY, w - 50, h + 50, 2 * p.PI, 0)
              p.arc(width - x - WIDTH_15, centerY, w - 50, h + 50, 0, 2 * p.PI);

              p.pop();

            } else if(p.soundEffect === 'c') {
              p.noFill();
              p.stroke(100, 100, 240);
              p.circle(centerX, centerY, height * level, height * level)
              p.circle(0 + WIDTH_15, centerY, height * level, height * level)
              p.circle(width - WIDTH_15, centerY, height * (level * 2), height * (level * 2))
            } else if(p.soundEffect === 'r') {
             
              // Rotating ellipses
              p.push()
              p.stroke(255);
              p.strokeWeight(8);
              p.translate(30, 30);
              p.rotate(-level % 100);
              p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
              p.color('magenta');
              p.pop();

              p.push()
              p.stroke(255);
              p.strokeWeight(8);
              p.translate(-30, 30);
              p.rotate(level % 100);
              p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
              p.pop();


              p.push()
              p.stroke(255);
              p.strokeWeight(8);
              p.translate(30, -30);
              p.rotate(level % 100);
              p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
              p.pop();

              p.push()
              p.stroke(255);
              p.strokeWeight(8);
              p.translate(-30, -30);
              p.rotate(-level % 100);
              p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
              p.pop();

              
            }
          }
        } 
        
        if(p.shape) {

          if(p.shape === 'i') {

            p.background(0);
            p.stroke(255,163,163);
            
            p.noStroke();
            p.translate(20, 20);

            let toggleHide = false;
            if(p.frameCount % 120 === 0) {
              toggleHide = true;
            }
            
            for (var i = 0; i <= eyesCount; i++) {
              eyes[i].draw1(p, level);
              eyes[i].draw2(p, eyesAngle);
              if(toggleHide) {
                eyes[i].setIsHidden(Math.random(0, 1) >= 0.8);
              }
            }

            eyesAngle+= 0.06;

          } else if(p.shape === 'o') {

          } else if(p.shape === 'l') {
            for(var i=0;i<NB;i++) Objects[i].draw(p, level);
          }
        }


        /* DJ NAME */
        if(p.djName) {
          p.push()
          p.fill(255);
          p.textFont(rFont);
          p.textSize((width/12) + (10 * level));
          p.text(p.djName, centerX, centerY);
          p.pop();
        }
        
        if(p.prompt && p.response) {
          p.push()
          p.fill(255);
          p.textFont(p.promptFont || blackCasperFont);
          p.textSize(WIDTH_15)
          p.text(p.prompt, centerX, centerY - (height / 6));
          p.pop()

          p.push()
          p.fill(255);
          p.textFont(p.responseFont || ransFont);
          p.textSize((width/12) + (WIDTH_15 * level));
          p.text(p.response, centerX, centerY);
          p.pop();
        }
      }
    }
  }

  const startExperience = () => {
    myp5.userStartAudio()
    setIsLoading(false);
    DOC.requestFullscreen();
  }

  if(myp5) {

    myp5.djName = props.djName;
    myp5.prompt = props.prompt;
    myp5.promptFont = FONTS[Math.floor(Math.random() * FONTS.length)];
    myp5.response = props.response;
    myp5.responseFont = FONTS[Math.floor(Math.random() * FONTS.length)]

    myp5.soundEffect = props.soundEffect;
    myp5.ageOfVideo = props.ageOfVideo;
    myp5.shape = props.shape;
  }

  if(props.ageOfVideo) {

    if(props.ageOfVideo === 'v' && ageOfVideo) {
      ageOfVideo.show();
      ageOfVideo.loop();
      ageOfVideo.position(0, 0);

      ageOfVideo2.hide();
      ageOfVideo3.hide();
    } else if(props.ageOfVideo === 'b') {
      ageOfVideo2.show();
      ageOfVideo2.loop();
      ageOfVideo2.position(0, 0);
      ageOfVideo2.size(width, height);

      ageOfVideo.hide();
      ageOfVideo3.hide();
    } else if(props.ageOfVideo === 'n') {

      ageOfVideo3.show();
      ageOfVideo3.loop();
      ageOfVideo3.position(0, 0);
      ageOfVideo3.size(width, height);

      ageOfVideo.hide();
      ageOfVideo2.hide();
    }
  } else if(ageOfVideo || ageOfVideo2) {
    ageOfVideo.hide();
    ageOfVideo2.hide();
    ageOfVideo3.hide();
  }

  if(props.shape) {
    if(props.shape === 'i') {
      var highCount = height/80;
      var wideCount = width/80;
      eyesCount = Math.floor(highCount * wideCount);
      
      var index = 0;
      for (var xc = 0; xc < wideCount; xc++) {
        for (var yc = 0; yc < highCount; yc++) {
          eyes[index++] = new Eye(Math.floor(xc)*u, Math.floor(yc)*u);
        }
      }
    } else if(props.shape === 'l') {
      if(Objects.length === 0) { 
        for(var i = 0;i<NB;i++) {
          console.log('new object: ', new object(i));
          Objects.push(new object(i));
        }
      }
  }
} else {
    eyes = [];
    Objects = [];
  }

  return (
    <>
      {isLoading && (<Button id="p5_init_sound" onClick={startExperience} sx={{ zIndex: 1000, position: 'absolute' }}>Start Experience</Button>)}
      <div ref={myRef}>

      </div>
    </>
  )
}

export default WatchCanvas;

// var cnv;

// var wid = 500;
// var hei = 300;



// var frame_count = 0;

// function activation(t) {
//     return ((1-cos(2*PI*t))/2)**1;
// }



// function setup() {

//   curSeed = 11;
//     noiseSeed(curSeed);
//     randomSeed(1);
    
//    createCanvas(wid,hei,SVG);
//       strokeWeight(1);   // do 0.1 for laser
//     stroke(255,0,0);      // red is good for laser
//   noFill();
//     //cnv.parent("canvas");
    
//     background(0);
    
//     for(var i = 0;i<NB;i++) {
//         Objects[i] = new object(i);
    
// }
//              // better not to have a fill for laser
// }





// function draw() {
// background(0);
    
//     var t = ((frame_count)%NB_FRAMES)/NB_FRAMES;
    
//     for(var i=0;i<NB;i++) Objects[i].draw();
  
//   noStroke();
//   fill(255);
//   	text("seed : " + curSeed, 10, 10);

//     frame_count++;
//     if (frame_count<=100 && frame_count>80) {
        
//     }
  
//   //////////////////////////////////////EXPORT
//   if (keyCode === LEFT_ARROW){
//       save("mySVG.svg");
//     print ("saved svg");
//   noLoop();	}
// }

// Opening and Closing Arcs
// p.arc(x, height/2, w + 100, h - 100, 0, -(p.PI * level), p.OPEN);
// p.arc(width - x, height/2, w + 100, h - 100, 0, -(p.PI * level));

// Opening and Closing Vertical Arcs
// p.arc(width / 2, y, h + 100, w - 100, (p.PI * level), 0)
// p.arc(width / 2, height - y, h + 100, w - 100, 0, -(p.PI * level));

// Rotating ellipses
// p.push()
// p.stroke(255);
// p.strokeWeight(8);
// p.translate(30, 30);
// p.rotate(-level % 100);
// p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
// p.color('magenta');
// p.pop();

// p.push()
// p.stroke(255);
// p.strokeWeight(8);
// p.translate(-30, 30);
// p.rotate(level % 100);
// p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
// p.pop();


// p.push()
// p.stroke(255);
// p.strokeWeight(8);
// p.translate(30, -30);
// p.rotate(level % 100);
// p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
// p.pop();

// p.push()
// p.stroke(255);
// p.strokeWeight(8);
// p.translate(-30, -30);
// p.rotate(-level % 100);
// p.ellipse(((width / 2)), ((height / 2)), 100 + (level * 100) * 10, 200);
// p.pop();
