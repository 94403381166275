// example theme with variants
export default {
  forms: {
    input: {
      border: 'none',
      borderRadius: 0,
      background: 'white',
      color: 'black'
    }
  }
}