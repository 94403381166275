import React, { useEffect, useState, useRef } from 'react';
import { getResponses } from './GoogleAPI';
import WatchCanvas from './WatchCanvas';
import questions from './questions';

const DJ_NAMES = {
  '1': 'Dj Bella Scratch',
  '2': 'Valerie Molano',
  '3': 'DJ CYN',
  '4': 'N3ptune',
  '5': 'Kid Astronaut',
  '6': ''
}

const DRAG_NAMES = {
  '9': 'Ms. Zarah',
  '0': 'Mariah Spani'
}

function Watch() {

  // This timer will change the question every 60 seconds
  let questionTimer;

  // This timer will change the user response every 10 seconds
  let responseTimer;

  // This timer will turn rage mode on and off
  let rageModeTimer;

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1);
  const [currentResponses, setCurrentResponses] = useState([]);
  const [responseIndex, setResponseIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [djKey, setDjKey] = useState(undefined);
  const [dragKey, setDragKey] = useState(undefined);
  const [soundEffect, setSoundEffect] = useState(undefined);
  const [ageOfVideo, setAgeOfVideo] = useState(undefined);
  const [shape, setShape] = useState(undefined);
  const [isUserResponsesOn, setIsUserResponsesOn] = useState(true);

  useEffect(() => {
    startExperience();

    return () => {
      clearInterval(questionTimer);
      clearInterval(responseTimer);
      questionTimer = null;
      responseTimer = null;
    }
  }, []);

  useEffect(() => {
    const loadQuestionResponses = async () => {
      const sheetName = `Q${currentQuestionIndex}`;
      const responses = await getResponses(sheetName);
      console.log('responses: ', responses);
      setCurrentResponses(responses);
    }

    loadQuestionResponses();
  }, [currentQuestionIndex]);

  const startExperience = () => {
    questionTimer = setInterval(changeQuestion, 1000 * 60);
    responseTimer = setInterval(changeResponse, 1000 * 15);
  }

  const changeQuestion = () => {
    console.log('Move to new question');
    setCurrentQuestionIndex(currentQuestionIndex => {
      return currentQuestionIndex === 5 ? 1 : currentQuestionIndex + 1
    });
  }

  const changeResponse = () => {
    setResponseIndex(responseIndex => {
        return responseIndex + 1;
    })
  }

  const handleKeyDown = (event) => {
    console.log('down event.key: ', event.key);
  }

  const handleDJNamePressed = (newDJKey) => {
    if(newDJKey === djKey) {
      setDjKey(undefined);
    } else {
      setDjKey(newDJKey);
    }
  }

  const handleSoundEffect = (key) => {
    if(key === soundEffect) {
      setSoundEffect(undefined);
    } else {
      setSoundEffect(key);
    }
  }

  const handleAgeOfVideo = (key) => {
    if(key === ageOfVideo) {
      setAgeOfVideo(undefined);
    } else {
      setAgeOfVideo(key);
    }
  }

  const handleShapes = (key) => {
    if(key === shape) {
      setShape(undefined);
    } else {
      setShape(key);
    }
  }

  const handleToggleUserResponses = () => {
    setIsUserResponsesOn(isUserResponsesOn => !isUserResponsesOn);
  }

  const handleKeyUp = (event) => {
    // console.log('up event.key: ', event.key);
    switch(event.key) {
      case 'u':
        handleToggleUserResponses()
      case '1': 
      case '2':
      case '3':
      case '4':
      case '5':
        console.log('show DJ text: ', event.key);
        handleDJNamePressed(event.key);
        break;
      case 'v': 
      case 'b':
      case 'n':
        console.log('show Age of video: ', event.key);
        handleAgeOfVideo(event.key);
        break;
      case 'a':
      case 'e':
      case 'c':
      case 'r':
        console.log('show sound effect: ', event.key);
        handleSoundEffect(event.key);
      case 'l':
      case 'o':
      case 'i':
        console.log('show shapes: ', event.key);
        handleShapes(event.key);
        break;
    }
  }

  const currentTextRow = currentResponses[Math.floor(Math.random() * currentResponses.length)];
  console.log('isUserResponsesOn: ', isUserResponsesOn);

  return (
    <div tabIndex={0} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
      <WatchCanvas 
        response={isUserResponsesOn ? currentTextRow : null} 
        prompt={isUserResponsesOn ? questions[currentQuestionIndex].promptText: null} 
        soundEffect={soundEffect}
        djName={djKey ? DJ_NAMES[djKey] : undefined}
        ageOfVideo={ageOfVideo}
        shape={shape} />
    </div>
  )
}

export default Watch;