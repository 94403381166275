import React from 'react';
import './App.css';
import { Route, Switch } from 'react-router-dom'

import { ThemeProvider } from 'emotion-theming'
import theme from './theme'

import Watch from './pages/watch/GinAndJazz';
import HoochBooch from './pages/watch/HoochBooch';
import Party from './pages/old/VoterParty';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <div className="content">
          <Switch>
            <Route path="/watch" component={Watch} />
            <Route path="/party" component={Party} />
            <Route path="/hoochbooch" component={HoochBooch} />
          </Switch>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
