import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'rebass';
import { ReactComponent as BoulderLogo } from '../../assets/Untitled (1).svg';
import { ReactComponent as MartiniGlass } from '../../assets/svg-icons/martini-glass.svg';
import { ReactComponent as Saxophone } from '../../assets/svg-icons/saxophone.svg';
// import { SVG } from '@svgdotjs/svg.js'

// import artistGif4Src from '../../assets/artist-assets/purnell3.gif';
import gif1File from '../../assets/hoochbooch/Hooch_Booch_Can_Animation_SameSize.gif';
import gif2File from '../../assets/hoochbooch/Hooch_Booch_Can_Animation.gif';
import gif3File from '../../assets/hoochbooch/Hooch_Booch_Neon_Final.gif';
import gif4File from '../../assets/hoochbooch/HoochBooch_Imagebackground.gif'; 
import gif5File from '../../assets/hoochbooch/Hoock_ImageCycle.gif'; 

const imageFiles = [gif1File, gif3File, gif2File, gif4File, gif5File];

const DOC = document.documentElement;

var mic;
let p5, myp5;
let p5Interval;
let c;
let windowHeight = window.innerHeight;
let windowWidth = window.innerWidth;
let HEIGHT = windowHeight;
let WIDTH = windowWidth;

let cycleInterval;

let gif1;
let gif2;
let gif3;
let gif4;
let gif5;

let localImageIndex;


const resizeFunc = () => {
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  HEIGHT = windowHeight;
  WIDTH = windowWidth;

  console.log('window on resize');

  if(myp5) {
    myp5.resizeCanvas(windowWidth, windowHeight);
    gif1 = myp5.createImg(imageFiles[localImageIndex]);
  }
}

window.addEventListener('resize', resizeFunc);

function Watch() {

  const myRef = React.createRef();
  const [isLoading, setIsLoading] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);
  localImageIndex = imageIndex;

  useEffect(() => {
    // Wait until p5 is loaded
    p5Interval = window.setInterval(() => {
      if(window.p5) {
        p5 = window.p5;
        myp5 = new p5(Sketch, myRef.current);
        window.clearInterval(p5Interval);
        setIsLoading(false);
      }
    }, 100);

    cycleInterval = window.setInterval(() => {
      console.log('cycle image');
      setImageIndex(oldIndex => {
        console.log('old index: ', oldIndex);
        let result;
        if(oldIndex === 4) {
          result = 0;
        } else {
          result = oldIndex + 1;
        }
        localImageIndex = result;

        gif1 = myp5.createImg(imageFiles[result]);
        return result;
      })
    }, 30000);

    return () => {
    }
  }, []);

  const Sketch = (p) => {

    p.setup = async function() {
      c = p.createCanvas(windowWidth, windowHeight);

      p.textAlign(p.CENTER, p.CENTER);
      p.imageMode(p.CENTER);

      gif1 = p.createImg(gif1File);
      gif1.hide();      
    }

    p.mousePressed = () => {
      startExperience();
    }

    p.draw = () => {
      if(!p.isLoading) {
        GIF(p, gif1);
      }
    }
  }

  let switchInterval;

  const handleKeyUp = (event) => {
    if(event.key === 'r') {
      console.log('event.key: ', event.key);
      myp5.resizeCanvas(windowWidth, windowHeight);
      gif1 = myp5.createImg(imageFiles[imageIndex]);
      // gif1.resize(windowHeight, windowHeight);
    }
  }

  const startExperience = () => {
    setIsLoading(false);
    DOC.requestFullscreen();
    window.dispatchEvent(new Event('resize'));
  }

  if(myp5) {
    myp5.isLoading = isLoading;
    myp5.imageIndex = imageIndex;
  }

  return (
    <>
      {isLoading ? (<Button id="p5_init_sound" onClick={startExperience} sx={{ position: 'absolute', zIndex: 1000 }}>Start Experience</Button>)
       : (
        <div tabIndex={0} onKeyUp={handleKeyUp} style={{ width: '100%', height: '100%', outline: 'none', display: 'flex', justifyContent: 'center', zIndex: 1000, backgroundColor: 'transparent' }}>
        </div>
       )}
    </>
  )
}

export default Watch;

const VIDEO = (p5, video) => {
  // p5.image(video, WIDTH, HEIGHT);
}

const GIF = (p5, gif) => {
  gif.show();
  gif.position(0, 0);
  gif.size(WIDTH, HEIGHT);
}

