import firebase from 'firebase/app';

export default {
  GOOGLE_API_KEY: 'AIzaSyDytgsrs7H9jF9RcxERxE7PFYwI31klhkc',
  discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4', 'https://script.googleapis.com/$discovery/rest?version=v1'],
  GOOGLE_SHEET_ID: '1PyEPH1PAIC3_uVzp057v0b7eqlOmIMOMCypGR-vv050',
  FIREBASE_CONFIG: {
    apiKey: "AIzaSyAijZCJujnnY5NqRtjZlL4g67x8sJ29Cuc",
    authDomain: "age-of-us.firebaseapp.com",
    projectId: "age-of-us",
    storageBucket: "age-of-us.appspot.com",
    messagingSenderId: "336494513251",
    appId: "1:336494513251:web:364b6575de7cffab377c56",
    measurementId: "G-QC405M8W3W"
  },
  FIREBASE_DB_URL: 'https://age-of-us-default-rtdb.firebaseio.com/',
  FIRE_BASE_APP: firebase.initializeApp({
    apiKey: "AIzaSyAijZCJujnnY5NqRtjZlL4g67x8sJ29Cuc",
    authDomain: "age-of-us.firebaseapp.com",
    projectId: "age-of-us",
    storageBucket: "age-of-us.appspot.com",
    messagingSenderId: "336494513251",
    appId: "1:336494513251:web:364b6575de7cffab377c56",
    measurementId: "G-QC405M8W3W"
  })
}